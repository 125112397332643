<template>
    <div class="index">
        <div class="header">
            <img src="../assets/images/ailogo.png"/>
            <ul class="mainMuen">
                <li v-for="(v,i) in menuList" :key="i" @click="choseMenu(i,v.sub[0].path)">
                {{v.mainName}} <span class="line" v-show="menuOne==i"></span>
                </li> 
            </ul>
            <div class="userInfo">
                <span>{{loginName}}</span>
                <i class="el-icon-switch-button" @click="loginOut"></i>
            </div>
        </div>
        <el-container>
            <el-aside width='206px'>
               <div class="leftMenu" >
                    <ul>
                        <li :class="subOne==k?'chose':''" v-for="(li,k) in subList" :key="k"
                        @click="choseSub(k,li.path)" >
                            <span class="dot"></span>{{li.name}}
                        </li> 
                    </ul>
                </div>
            </el-aside>
            <el-main>
                <router-view @changeMune='changeMune'></router-view>
            </el-main>
        </el-container>
       
    </div>
</template>
<script>
import {allFun} from '../service/tool'
import {teaMenuList,companyMenuList} from '../service/config'
export default {
    data () {
        return {
            userType:false,  //false 为企业,
            loginName:'',
            menuList:companyMenuList,
            subList:[],
            menuOne:0,
            subOne:0,
            isManager:false  //默认不是就业管理员,限制企业库+双选会统计权限
        }
    },
    methods: {
        // 子组件路由跳转切换
        changeMune(i,k,path){
            this.menuOne=i
            this.subList=this.menuList[i].sub
            this.subOne=k
            this.$router.push({path:path})
        },
        //主菜单的切换
        choseMenu(i,firstPath){
            this.menuOne=i
            this.subList=this.menuList[i].sub
            this.subOne=0
            this.$router.push({path:firstPath})
          
        },
        //主菜单下的次菜单切换
        choseSub(k,path){        
            this.subOne=k
            this.$router.push({path:path})
        },
        loginOut(){
            this.$confirm('此操作将退出平台, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.$htp.post_('/user/logout').then(res=>{
                    if(res.code==0){
                        sessionStorage.clear()
                        this.$router.push({path:'/login'})
                    }              
                }) 
            }).catch(() => {});    
        }
    },
    mounted(){
        let menuList=JSON.parse(JSON.stringify(teaMenuList))
        this.loginName=sessionStorage.getItem('nickName')   
        if(sessionStorage.getItem('userType')){
            //存在userType==0就表示是普通老师
            if(sessionStorage.getItem('isManager')==0){
               //二维数组的筛选用forEach+filter
               menuList.forEach((v)=>{
                   let sub=v.sub
                   v.sub=sub.filter(e=>e.show!==1)
               })
            }
            this.menuList=menuList
            this.choseMenu(0,'/teahome')
        }else{
            this.choseMenu(0,'/home')
            
        }
        window.onresize = function(){
                allFun.funHeight(document.getElementsByClassName("leftMenu")[0], 70)
         }
        // allFun.funHeight(document.getElementsByClassName("leftMenu")[0], 70)
    }
}
</script>
<style scoped lang="scss">
@import '../assets/css/index.scss';
</style>